const blogContent = {
    german: {
        headline: "BLOG",
        callForPapersHeadline: "Open Call for Participation",
        callForPapers: "Im Frühjahr 2020 wurden wir vom nOu-Kollektiv mit einer überwältigende Resonanz auf unseren Open Call for Participation überrascht. Seitdem hat sich einiges geändert: Aufgrund der unsicheren Situation durch die Corona-Pandemie haben wir beschlossen, das Festival von November 2020 auf Mai 2021 zu verschieben. Wir schätzen uns sehr glücklich, dass alle Beteiligten des Festivals mit uns diesen Schritt gegangen sind und freuen uns nun sehr, uns alle beim Festival im Mai 2021 kennenzulernen. Hier findet ihr den Open Call for Participation von 2020:",
        callForPapersLink: "Open Call for Participation, deutsch, english, español (PDF)",
        secondHeadlineEleventh: "Vor wem haben wir Angst? „Der Fremde“ als rassistische und klassistische Projektion",
        eleventh: "Der schwarze männliche Körper wird als Fremder an einem Ort, der vom weißen Blick als weiß imaginiert wird, in einen Körper verwandelt, der als störend, als „merkwürdig“, als potentiell gefährlich gelesen wird. Diese Horror-Phantasie des gefährlichen „Anderen“ stellt weiße Mittelklassefrauen als Opfer dar, die des Schutzes durch die prototypischen weißen männlichen Helden würdig sind, die für „ihre Frauen“ kämpfen. Weiße Frauen werden so zur Verkörperung des reinen Weißseins im Gegensatz zum imaginierten „Anderen“.",
        secondHeadlineTenth: "Den Narrativ ändern – Ein kleiner Bericht vom Workshop „Documenting Nocturnal Flâneuserie“ von Anna Benner und Zoë Aiano",
        tenth: "In unseren zweitägigen Workshops „Documenting Nocturnal Flâneusrie“ von Anna Benner und Zoë Aiano am 16.-17. Januar und 18.-19. Januar haben wir genau das versucht, was der Titel ankündigt: jede der Teilnehmer:innen zog für sich in die Nacht, um ihr nächtliches Flanieren mit Film- und Sprachaufnehmen sowie wie Fotos zu dokumentieren. Getrieben von der Frage, was das nächtliche Flanieren für jede:n individuell aber auch kollektiv als weiblich identifizierte und nicht-binäre Menschen bedeutet. Kann es so etwas wie die Flaneuse geben? In diesem Blogeintrag reflektieren wir die Diskussionen und geben euch zusätzliches Material an die Hand, um ihre Stadt mit visuellen Mitteln zu entdecken.",
        secondHeadlineFirst: "Solidarität mit Kristina Hänel – My body, my choice gilt in Deutschland immer noch nicht.",
        first: "Im Januar 2021 hat das Oberlandesgericht in Frankfurt am Main die Ärztin Kristina Hänel zur Strafe nach §219a des Strafgesetzbuches (“Werbung für den Abbruch der Schwangerschaft”) verurteilt.",
        secondHeadlineSecond: "„No Justice, No Choice“ pro-choice intersectional denken",
        second: "Spätestens seitdem die 68er-Feminist:innen das Abtreibungsverbots im §218 des Deutschen Strafgesetzbuches – wohlgemerkt direkt neben anderen „Straftaten gegen das Leben“ wie Totschlag – skandalisiert haben, ist der Kampf für das Recht auf Abtreibung als Recht auf Entscheidung über den eigenen Körper zentral in der feministischen Bewegung in Deutschland.",
        secondHeadlineThird: "nOu Logo",
        third: "Wir haben endlich ein Logo, dank dessen Nocturnal Unrest super schnell erkennbar ist. Schon in unserer Grafik drückt sich nun die Grundidee hinter nOu aus: interessant wird es da, wo die Grenzen zwischen Tag und Nacht verwischen. Daher steht im Zentrum des Logos ein Farbverlauf, der die Buchstaben unseres Namens n – o – u miteinander verbindet. Nicht hell und dunkel, sondern Dämmerung und Morgengrauen rücken ins Zentrum.",
        secondHeadlineFourth: "The Strange Half-Absence Of Wandering At Night",
        fourth: "Nachts spazieren gehen, sich einfach durch die Straßen treiben lassen? Für viele, vor allem für weiblich gelesene, Personen ist das mit Angst verbunden. Von klein auf kriegen insbesondere Mädchen erzählt, dass es für sie nicht mehr sicher draußen ist sobald es dunkel wird. Wer doch unterwegs sein muss, geht häufig Umwege, geht schneller oder langsamer, achtet auf jedes Geräusch – jede Bewegung ist bewusst; sich aber Zeit nehmen zum Treiben lassen und Verlaufen? Der Audiowalk „The Strange Half-Absence of Wandering At Night“ von Johanna Steindorf begleitet historische und fiktive Frauenfiguren mit Einkehr der Dämmerung bis in die Nacht und versucht den Spieß umzudrehen: Was ist, wenn die Nacht auch Schutz bieten kann? Wenn sie uns unsichtbar macht, Eindeutigkeiten verwischt, kann sie auch Freiheit bieten? Oder müssen sich als weiblich gelesene Körper die Freiheit durch die Nacht zu flanieren immer wieder neu aneignen?",
        secondHeadlineFifth: "Edit-a-Night",
        fifth: "Der Brockhaus ist 2020 längst passé, unser allgemeines und nicht so allgemeines Wissen holen wir aus der Wikipedia. Umsonst, kollektiv, irgendwie basisdemokratisch. Gutes Projekt, oder? Aber wer schreibt eigentlich dieses „Welt-Wissen“? In einer Umfrage von 2018 stellte die Wikimedia Foundation fest, dass nur 9% der Wikipedia-Autor:innen Frauen sind. Und um die Einträge weiblicher Persönlichkeiten steht es auch nicht gut. Seit Langem beschäftigen sich Eva Königshofen, Hannah Schmedes und Lena Wassermeier mit den Gründen für dieses strukturelle Ungleichgewicht, kritisieren die Konsequenzen – und tun selbst ganz praktisch etwas dagegen: mit feministischen Editierungs-Runden, in denen die Teilnehmenden gemeinsam lernen, wie Wikipedia funktioniert und wie frau sicher geht, dass Admins nicht direkt alles wieder löschen.",
        secondHeadlineSixth: "Lesbian Seeing",
        sixth: "A while ago I was sitting at our large garden table with two colleagues whom I’d invited to the post-socialist countryside. As usual, I was attempting to play the perfect housewife, hostess and butler all at once. So, along with the aperitif, I’d also brought a mortar outside, full of salt, garlic and pine nuts, and while we wondered aloud how it was possible that even though there was not a single university chair of feminist philosophy in Germany, right-wingers were still gibbering about a gender-ideological takeover of the universities, I endeavoured to crush a basil plant into pesto. ",
        secondHeadlineSeventh: "Second Sight / das zweite Gesicht. Translator’s Note on ‚Lesbian Seeing‘",
        seventh: "Walking in the fields, where half the sky is bright pink, and the other half is already dark and still, I turn around and feel that I am two selves drifting away from each other. The self which reflects has no access to the one which acts. The self which lives is floating away from me. She is a comet with a long tail filled with debris that obscures my vision.",
        secondHeadlineEighth: "Taking decisions in the Dark.",
        eighth: "As an unresting feminist collective, we are convinced that acknowledging our entanglement(s) with others is one way to overcome traditionally masculinist and colonial, racist ways of thinking. The latter leave our interdependence, our shortcomings, our bounded perspectives in the dark. Consequently, the dark becomes the shelter of the unspoken, the yet-undecided and of that which is unwanted in the brightness/whiteness of what’s considered “normal”.[1] We, however, are unwilling to pretend these do not exist just because they are made invisible. Nocturnal Unrest is interested in this kind of darkness. From the beginning it was clear that this is where we would have to go, all the way from writing, to curating, to putting the festival on its feet. An indeed did we find ourselves groping in the dark quite a bit.",
        secondHeadlineNinth: "On (Self-)Care and why we postponed Nocturnal Unrest to early Summer 2021",
        ninth: "We have given a lot of thought to what the current global pandemic means for Nocturnal Unrest. After much deliberation, discussion and reasoning, we have come to the conclusion that we will postpone the festival until (early) summer 2021. An exact date should be fixed in late autumn. ",
        link: "weiterlesen",
        languageIndicationEnglish: "Dieser Text ist nur auf Englisch verfügbar.",
        languageIndicationGerman: "",
        
    },
    english:  {
        headline: "BLOG",
        callForPapersHeadline: "Open Call for Participation",
        callForPapers: "In spring 2020, we received an overwhelming response to our Open Call for Participation. Since then, a lot has changed: Due to the danger of the Corona pandemic, the festivals had been postponed from November 2020 to May 2021. We are incredibly happy and thankful that everyone involved in the festival has taken this step with us. We are now very much looking forward to meeting everyone at the festival in May 2021. Here you can find the Open Call for Participation from 2020:",
        callForPapersLink: "Open Call for Participation, deutsch, english, español (PDF)",
        secondHeadlineEleventh: "Who are we afraid of? The „Stranger“ as a Racist and Classist Projection",
        eleventh: "The black male body is othered as a stranger in a place imagined as white by the white gaze and thus turned into a body that is read as disruptive, as “off”, as potentially dangerous. This horror-phantasy of the dangerous “other”posits white middle-class women as victims worthy of protection by the prototypical white male heroes who fight for “their women”. White women thus become the embodiment of pure whiteness in contrast to the imagined “other”. ",
        secondHeadlineTenth: "Change the narrative – A short report from the workshop „Documenting Nocturnal Flâneuserie“ by Anna Benner and Zoë Aiano",
        tenth: "In our two-day workshop „Documenting Nocturnal Flâneuserie“ with Anna Benner and Zoë Aiano on 16-17 January and 18-19 January, we tried to do exactly what the title suggests: document. Each of the female and non-binary participants went out into the night to capture their nocturnal strolling through film, voice recordings and photos. In turn, the camera changed how we perceived ourselves and the nocturnal city. We were driven by the question of what walking the city at night means for each of us individually as well as collectively as female-identified and non-binary people. Can there be such a thing as the flâneuse? In this blog entry we reflect on the discussions and provide readers with the additional material to discover their city through visual means.",
        secondHeadlineFirst: "Solidarität mit Kristina Hänel – My body, my choice gilt in Deutschland immer noch nicht.",
        first: "Im Januar 2021 hat das Oberlandesgericht in Frankfurt am Main die Ärztin Kristina Hänel zur Strafe nach §219a des Strafgesetzbuches (“Werbung für den Abbruch der Schwangerschaft”) verurteilt.",
        secondHeadlineSecond: "„No Justice, No Choice“ pro-choice intersectional denken",
        second: "Spätestens seitdem die 68er-Feminist:innen das Abtreibungsverbots im §218 des Deutschen Strafgesetzbuches – wohlgemerkt direkt neben anderen „Straftaten gegen das Leben“ wie Totschlag – skandalisiert haben, ist der Kampf für das Recht auf Abtreibung als Recht auf Entscheidung über den eigenen Körper zentral in der feministischen Bewegung in Deutschland.",
        secondHeadlineThird: "nOu Logo",
        third: "We finally have a logo that makes Nocturnal Unrest super easy to recognise. Thanks to this visualisation, the basic idea behind nOu is already expressed in our graphics: it gets interesting where the boundaries between day and night blur. Therefore, at the centre of the logo is a colour gradient that connects the letters of our name n – o – u. Not light and dark, but twilight and dawn come into focus.",
        secondHeadlineFourth: "The Strange Half-Absence Of Wandering At Night",
        fourth: "Taking a walk at night, just letting yourself drift through the streets? For many people, especially people read as woman, this is associated with fear. From an early age, girls in particular are told that it is no longer safe for them to go outside once it gets dark. If we as women do have to be on the road, we take detours, walk faster or slower, pay attention to every noise – but in any case consciously; there is no time to drift around and get lost. The audio walk „The Strange Half-Absence of Wandering At Night“ by Johanna Steindorf accompanies historical and fictional female characters as they walk through the twilight until late at night, trying to turn the tables: What if the night can also offer protection? If it makes us invisible, blurs ambiguities, can it also offer freedom?",
        secondHeadlineFifth: "Edit-a-Night",
        fifth: "Der Brockhaus ist 2020 längst passé, unser allgemeines und nicht so allgemeines Wissen holen wir aus der Wikipedia. Umsonst, kollektiv, irgendwie basisdemokratisch. Gutes Projekt, oder? Aber wer schreibt eigentlich dieses „Welt-Wissen“? In einer Umfrage von 2018 stellte die Wikimedia Foundation fest, dass nur 9% der Wikipedia-Autor:innen Frauen sind. Und um die Einträge weiblicher Persönlichkeiten steht es auch nicht gut. Seit Langem beschäftigen sich Eva Königshofen, Hannah Schmedes und Lena Wassermeier mit den Gründen für dieses strukturelle Ungleichgewicht, kritisieren die Konsequenzen – und tun selbst ganz praktisch etwas dagegen: mit feministischen Editierungs-Runden, in denen die Teilnehmenden gemeinsam lernen, wie Wikipedia funktioniert und wie frau sicher geht, dass Admins nicht direkt alles wieder löschen.",
        secondHeadlineSixth: "Lesbian Seeing",
        sixth: "A while ago I was sitting at our large garden table with two colleagues whom I’d invited to the post-socialist countryside. As usual, I was attempting to play the perfect housewife, hostess and butler all at once. So, along with the aperitif, I’d also brought a mortar outside, full of salt, garlic and pine nuts, and while we wondered aloud how it was possible that even though there was not a single university chair of feminist philosophy in Germany, right-wingers were still gibbering about a gender-ideological takeover of the universities, I endeavoured to crush a basil plant into pesto. ",
        secondHeadlineSeventh: "Second Sight / das zweite Gesicht. Translator’s Note on ‚Lesbian Seeing‘",
        seventh: "Walking in the fields, where half the sky is bright pink, and the other half is already dark and still, I turn around and feel that I am two selves drifting away from each other. The self which reflects has no access to the one which acts. The self which lives is floating away from me. She is a comet with a long tail filled with debris that obscures my vision.",
        secondHeadlineEighth: "Taking decisions in the Dark.",
        eighth: "As an unresting feminist collective, we are convinced that acknowledging our entanglement(s) with others is one way to overcome traditionally masculinist and colonial, racist ways of thinking. The latter leave our interdependence, our shortcomings, our bounded perspectives in the dark. Consequently, the dark becomes the shelter of the unspoken, the yet-undecided and of that which is unwanted in the brightness/whiteness of what’s considered “normal”.[1] We, however, are unwilling to pretend these do not exist just because they are made invisible. Nocturnal Unrest is interested in this kind of darkness. From the beginning it was clear that this is where we would have to go, all the way from writing, to curating, to putting the festival on its feet. An indeed did we find ourselves groping in the dark quite a bit.",
        secondHeadlineNinth: "On (Self-)Care and why we postponed Nocturnal Unrest to early Summer 2021",
        ninth: "We have given a lot of thought to what the current global pandemic means for Nocturnal Unrest. After much deliberation, discussion and reasoning, we have come to the conclusion that we will postpone the festival until (early) summer 2021. An exact date should be fixed in late autumn. ",
        link: "continue reading",
        languageIndicationGerman: "This text is only available in German.",
        languageIndicationEnglish: "",
    }
}

export {blogContent}