import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../components/template"
import "../styles/blog.css"
import { blogContent } from "../content/blog"
import { SettingsContext } from "../contexts/settings"
import { graphql } from "gatsby"
import {renderMarkdown} from "../../utils/rendermarkdown"
import { translate } from "../../utils/language"

const compareElements = (a,b) => {
	const dayCompare = a.Datum.localeCompare(b.Datum)
	if (dayCompare !== 0) {
		return dayCompare
	}	
}

const BlogPage = ({ data }) => {
    const [settings, ] = useContext(SettingsContext)
    const content = blogContent[settings.language]
    const l = settings.language
    const sortedEvents = data.strapi.blogPosts.filter(element => element.Datum).sort(compareElements).reverse()
    return (
        <Template currentPage="blog">
            <section className="blog-entries">
            <div>
                <h1>{content.headline}</h1>
                <p>
					{renderMarkdown(translate(l, data.strapi.blogpostUebersicht.Intro.Deutsch, data.strapi.blogpostUebersicht.Intro.Englisch))}
                </p>

                {
                    sortedEvents.map(blogpost => {
                        return <div>
                            <h2>
                                <Link className="link-mint" to={`/blog/${blogpost.readable_id}`}>
                                 {translate(l, blogpost.Titel, blogpost.Titel_en)}
                                </Link>
                            </h2>
                            <p className="blog-abstand-link">
                                {renderMarkdown(translate(l, blogpost.Teasertext.Deutsch,blogpost.Teasertext.Englisch))}
                            </p>
                            <Link to={`/blog/${blogpost.readable_id}`} className="blog-link">{content.link}</Link>
                        </div>
                    })
                }

                <h2><Link className="link-mint" id="who-are-we-afraid-of" to="/blog/who-are-we-afraid-of">{content.secondHeadlineEleventh}</Link></h2>
                <div>
                    <p> 
                        {content.eleventh}
                    </p>
                    <Link to="/blog/who-are-we-afraid-of" className="blog-link">{content.link}</Link>
                </div>
                <h2><Link className="link-mint" id="change-the-narrative" to="/blog/change-the-narrative">{content.secondHeadlineTenth}</Link></h2>
                <div>
                    <p> 
                        {content.tenth}
                    </p>
                    <Link to="/blog/change-the-narrative" className="blog-link">{content.link}</Link>
                </div>
                <h2><Link className="link-mint" id="solidaritaet-mit-Kristina-haenel" to="/blog/solidaritaet-mit-Kristina-haenel">{content.secondHeadlineFirst}</Link></h2>
                <p>{content.languageIndicationGerman}</p>
                <div >
                    <p> 
                        {content.first}
                    </p>
                    <Link to="/blog/solidaritaet-mit-Kristina-haenel" className="blog-link">{content.link}</Link>
                </div>
                <h2><Link className="link-mint" id="no-justice-no-choice" to="/blog/no-justice-no-choice">{content.secondHeadlineSecond}</Link></h2>
                <p>{content.languageIndicationGerman}</p>
                <div>
                    <p> 
                        {content.second}
                    </p>
                    <Link to="/blog/no-justice-no-choice" className="blog-link">{content.link}</Link>
                </div>

                <h2><Link className="link-mint" id="nOu-logo" to="/blog/nOu-logo">{content.secondHeadlineThird}</Link></h2>
                <div>
                    <p> 
                        {content.third}
                    </p>
                    <Link to="/blog/nOu-logo" className="blog-link">{content.link}</Link>
                </div>

                <h2><Link className="link-mint" id="the-strange-half-absence-of-wandering-at-night" to="/blog/the-strange-half-absence-of-wandering-at-night">{content.secondHeadlineFourth}</Link></h2>
                <div>
                    
                    <p>
                        {content.fourth}
                    </p>
                    <Link to="/blog/the-strange-half-absence-of-wandering-at-night" className="blog-link">{content.link}</Link>
                </div>

                <h2><Link className="link-mint" id="edit-a-night" to="/blog/edit-a-night">{content.secondHeadlineFifth}</Link></h2>
                <p>{content.languageIndicationGerman}</p>
                <div>
                    <p> 
                        {content.fifth}
                    </p>
                    <Link to="/blog/edit-a-night" className="blog-link">{content.link}</Link>
                </div>

                <h2><Link className="link-mint" id="lesbian-seeing" to="/blog/lesbian-seeing">{content.secondHeadlineSixth}</Link></h2>
                <p>{content.languageIndicationEnglish}</p>
                <div>
                    <p> 
                    {content.sixth}
                    </p>
                    <Link to="/blog/lesbian-seeing" className="blog-link">{content.link}</Link>
                </div>
                <h2><Link className="link-mint" id="second-sight" to="/blog/second-sight">{content.secondHeadlineSeventh}</Link></h2>
                <p>{content.languageIndicationEnglish}</p>
                <div>
                    <p> 
                    {content.seventh}
                    </p>
                    <Link to="/blog/second-sight" className="blog-link">{content.link}</Link>
                </div>
                <h2><Link className="link-mint" id="taking-decisions-in-the-dark" to="/blog/taking-decisions-in-the-dark">{content.secondHeadlineEighth}</Link></h2>
                <p>{content.languageIndicationEnglish}</p>
                <div>
                    <p> 
                    {content.eighth}
                    </p>
                    <Link to="/blog/taking-decisions-in-the-dark" className="blog-link">{content.link}</Link>
                </div>
                <h2><Link className="link-mint" id="on-self-care-and-why-we-postponed-nocturnal-unrest" to="/blog/on-self-care-and-why-we-postponed-nocturnal-unrest">{content.secondHeadlineNinth}</Link></h2>
                <p>{content.languageIndicationEnglish}</p>
                <div>
                    <p>
                        {content.ninth}
                    </p>
                    <Link to="/blog/on-self-care-and-why-we-postponed-nocturnal-unrest" className="blog-link">{content.link}</Link>
                </div>
                <h2>{content.callForPapersHeadline}</h2>
                <div>
                    <p>
                        {content.callForPapers}
                    </p>
                    <a  href="/documents/Open_Call_for_Papers_dt.en.span.pdf" target="_blank" rel="noopener noreferrer" className="blog-link">
                        {content.callForPapersLink}
                    </a>
                </div>
            </div>    


           
            </section>
        </Template >
    )
}

export default BlogPage

export const pageQuery = graphql`
query MyQuery2 {
    strapi {
      blogpostUebersicht {
        Intro {
          Deutsch
          Englisch
        }
      }
    blogPosts {
        readable_id
        Datum
        Titel
        Titel_en
        Teasertext {
            Deutsch
            Englisch
        }
        Vorschaubild {
            Deutsch
            Englisch
            Untertitel_de
            Untertitel_en
            Bild {
            url
            }
        }
    }
    }
  }
`